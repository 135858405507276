<template>
  <component :is="$store.state.homepageRootComponent" />
</template>

<script>
import { GET_PAGE_CONTENT } from '~/store/index';

import PhoenixLandingPage, { isPhoenixRequest, phoenixLayout } from '~/components/phoenix/PhoenixLandingPage.vue';

export default {
  layout(context) {
    if (isPhoenixRequest(context)) {
      return phoenixLayout;
    }
    return null;
  },

  head() {
    return (
      this.$store.state.pageContent &&
      this.$store.state.pageContent.meta
    ) || {};
  },

  components: {
    PhoenixLandingPage,
  },

  async fetch(context) {
    if (isPhoenixRequest(context)) {
      await context.store.dispatch(GET_PAGE_CONTENT, {
        routePath: '/phoenix',
        context,
      });

      context.store.commit('updateHomepageRootComponent', 'PhoenixLandingPage');
    } else {
      context.error({ statusCode: 404, message: 'Page not found' });
    }
  },
};
</script>

