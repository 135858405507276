<template>
  <div :class="$s.table">
    <o-heading size="display-2">Compare our plans</o-heading>
    <p>Choose from one of our three site plans based on the features you and
    your clients need for each site you publish. Discounted pricing for all
    your sites is also available by upgrading your account to WebPro for a
    low monthly or annual platform fee.
    </p>
    <div :class="$s.container">
      <div :class="$s.bigRow"/>
      <div :class="$s.notAsGrey"><strong>Platform fee</strong></div>
      <div>
        <o-heading
          :class="$s.green"
          size="title-3"
        >
          Pro
        </o-heading>
      </div>
      <div>
        <o-heading
          :class="$s.blue"
          size="title-3"
        >
          Business
        </o-heading>
      </div>
      <div>
        <o-heading
          :class="$s.purple"
          size="title-3"
        >
          Performance
        </o-heading>
      </div>

      <div :class="$s.bigRow"><o-heading size="title-3">Lite</o-heading></div>
      <div :class="$s.notAsGrey">$0/mo</div>
      <div>$15/mo</div>
      <div>$30/mo</div>
      <div>$45/mo</div>

      <div :class="[$s.darkRow, $s.bigRow]"><o-heading size="title-3">WebPro</o-heading></div>
      <div :class="$s.notAsGrey">$30/mo</div>
      <div>$8/mo</div>
      <div>$20/mo</div>
      <div>$30/mo</div>

      <div :class="$s.bigRow"/>
      <div/>
      <div/>
      <div/>
      <div/>

      <div><o-heading size="title-5">Site Features</o-heading></div>
      <div/>
      <div/>
      <div/>
      <div/>

      <div :class="$s.darkRow">No Weebly Ads</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div>Unlimited Storage</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Connect Your Domain</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div>SSL Security</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Advanced Site Stats</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div>Site Search</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Password Protection</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div>Video Backgrounds</div>
      <div/>
      <div><o-icon
        :class="$s.green"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Members</div>
      <div/>
      <div>Up to 100</div>
      <div>Unlimited</div>
      <div>Unlimited</div>

      <div>Membership Registration</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.bigRow"/>
      <div/>
      <div/>
      <div/>
      <div/>

      <div><o-heading size="title-5">Ecommerce Tools</o-heading></div>
      <div/>
      <div/>
      <div/>
      <div/>

      <div :class="$s.darkRow">Weebly Transaction Fee</div>
      <div/>
      <div>3%</div>
      <div>0%</div>
      <div>0%</div>

      <div>Number of Products</div>
      <div/>
      <div>Up to 25</div>
      <div>Unlimited</div>
      <div>Unlimited</div>

      <div :class="$s.darkRow">Checkout</div>
      <div/>
      <div>On Weebly</div>
      <div>On your domain</div>
      <div>On your domain</div>

      <div>Digital Goods</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Product Reviews</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div>Inventory Management</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Tax Calculator</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div>Coupons</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.blue"
        name="check"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Real Time Shipping Rates</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div>Abandoned Cart Emails</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>

      <div :class="$s.darkRow">Weebly Gift Cards</div>
      <div/>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.grey"
        name="circle"
      /></div>
      <div><o-icon
        :class="$s.purple"
        name="check"
      /></div>
    </div>
  </div>
</template>

<script>
import { OIcon } from 'orbit-ui/components/Icon';
import { OHeading } from 'orbit-ui/components/Heading';

export default {
  components: {
    OIcon,
    OHeading,
  },
};
</script>

<style lang="scss" module="$s">
.table {
  text-align: center;
  margin: 0 auto;

  & > p {
    max-width: 800px;
    margin: 0 auto;
  }
}


.container {
  margin-top: 50px;
  display: grid;
  background: linear-gradient(to right, white 300px, #A8ADB2 300px);
  grid-template-columns: 220px 150px repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 1px;
  grid-row-gap: 0px;
  align-items: center;
  justify-content: stretch;

  & > * {
    padding: 0 20px;
    background-color: white;
    height: 46px;
    line-height: 46px;

    &:nth-child(5n+1) {
      text-align: left;
    }
  }

  .bigRow,
  .bigRow > div,
  .bigRow + div,
  .bigRow + div + div,
  .bigRow + div + div > div,
  .bigRow + div + div + div,
  .bigRow + div + div + div > div,
  .bigRow + div + div + div + div,
  .bigRow + div + div + div + div > div {
    line-height: 74px !important;
    height: 74px;
  }

  /** abandon hope, all ye who come here **/
  & > div:nth-child(5n+1):not(.darkRow) + div + div + div + div {
    box-shadow: 1px 0px 0px 0px white;
  }

  .darkRow,
  .darkRow + div + div + div + div {
    box-shadow: 1px 0px 0px 0px #F9F9F9;
  }

  .darkRow,
  .darkRow + div,
  .darkRow + div + div,
  .darkRow + div + div + div,
  .darkRow + div + div + div + div {
    background-color: #F9F9F9;
  }
}

.gridItem {
  text-align: center;
  padding: 10px 0;
}


.darkGrid {
  background-color: #F9F9F9;
}

.noBottomMargins {
  margin-bottom: 0 !important;
}

.green {
  color: #1AB40E;
}

.blue {
  color: #3363FF;
}

.purple {
  color: #AB62FA;
}

.grey {
  color: #CFCFCF;
}

.notAsGrey {
  color: #8C9195;
}
</style>
