<template>
  <div>
    <phoenix-hidden-banner
      :page-data="pageData"
      :class="[$s.topBar, {
        [$s.active]: topBarActive,
    }]" />
    <phoenix-header-section
      :page-data="pageData"
      @showTopBar="showTopBar"
      @hideTopBar="hideTopBar" />
    <phoenix-feature-section :page-data="pageData" />
    <phoenix-svg-section :page-data="pageData" />
    <phoenix-pricing-section :page-data="pageData" />
    <phoenix-quote-section :page-data="pageData" />
  </div>
</template>

<script>
import PhoenixHiddenBanner from '~/components/phoenix/PhoenixHiddenBanner';
import PhoenixHeaderSection from '~/components/phoenix/PhoenixHeaderSection';
import PhoenixFeatureSection from '~/components/phoenix/PhoenixFeatureSection';
import PhoenixSvgSection from '~/components/phoenix/PhoenixSvgSection';
import PhoenixPricingSection from '~/components/phoenix/PhoenixPricingSection';
import PhoenixQuoteSection from '~/components/phoenix/PhoenixQuoteSection';

export default {
  layout: 'phoenix-landing-page',

  components: {
    PhoenixHiddenBanner,
    PhoenixHeaderSection,
    PhoenixFeatureSection,
    PhoenixSvgSection,
    PhoenixPricingSection,
    PhoenixQuoteSection,
  },

  data() {
    return {
      pageData: this.$store.state.pageContent,
      topBarActive: false,
    };
  },

  methods: {
    showTopBar() {
      this.topBarActive = true;
    },
    hideTopBar() {
      this.topBarActive = false;
    },
  },
};

export const isPhoenixRequest = context => context.req && context.req.headers && context.req.headers['x-phoenix-landing-page'];

export const phoenixLayout = 'phoenix-landing-page';
</script>

<style module="$s" lang="scss">
.topBar {
  opacity: 0;
  transition: opacity var(--transition-base);
  pointer-events: none;
  z-index: 1;

  &.active {
    pointer-events: initial;
    opacity: 1;
  }
}
</style>
