import { render, staticRenderFns } from "./PhoenixPricingSection.vue?vue&type=template&id=7264dc1e"
import script from "./PhoenixPricingSection.vue?vue&type=script&lang=js"
export * from "./PhoenixPricingSection.vue?vue&type=script&lang=js"
import style0 from "./PhoenixPricingSection.vue?vue&type=style&index=0&id=7264dc1e&prod&module=%24s&lang=scss"




function injectStyles (context) {
  
  this["$s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports