<template>
  <div :class="$s.quoteBox">
    <o-heading :class="$s.quote">
      {{ pageData.quote.text }}
    </o-heading>
    <o-heading size="title-5">
      {{ pageData.quote.quoteAttributor }}
    </o-heading>
  </div>
</template>

<script>
import { OHeading } from 'orbit-ui/components/Heading';
import { OGrid, OGridItem } from 'orbit-ui/components/Grid';
import { OButton } from 'orbit-ui/components/Button';

import PhoenixResponsiveImage from '~/components/phoenix/PhoenixResponsiveImage';

export default {

  components: {
    OHeading,
    OGrid,
    OGridItem,
    OButton,
    PhoenixResponsiveImage,
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style module="$s" lang="scss">
.quoteBox {
  text-align: center;
  padding: 50px;
  max-width: 700px;
  margin: 0 auto;

  @media (--for-tablet-landscape-up) {
    padding: 100px 50px;
  }

  .quote {
    margin-bottom: 30px;
  }
}
</style>
