<template>
  <!--
    The base64 image src is a blank image to make the browser happy.
    This blank image is used so there isn't a 'jump' in the browser
    when the js takes over what was server rendered and lazy loads in the actual image
   -->
  <img
    v-lazy="imageSrc"
    :key="imageSrc"
    :alt="alt"
    :title="alt"
    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
  >
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';

const getImage = require.context('~/assets/img/phoenix/', false, /\.png$/);
let debouncedImageResizeHandler = null;

export default {
  props: {
    filename: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      imageSrc: '',
    };
  },

  computed: {
    ...mapGetters([
      'cdnBaseUrl',
    ]),
  },

  mounted() {
    this.responsiveImageResize();
    debouncedImageResizeHandler = debounce(this.responsiveImageResize.bind(this), 200, { leading: true });
    window.addEventListener('resize', debouncedImageResizeHandler);
  },

  beforeDestroy() {
    window.removeEventListener('resize', debouncedImageResizeHandler);
  },

  methods: {
    responsiveImageResize() {
      const image = getImage(`./${this.$props.filename}-${this.imageWidth()}.png`);
      this.imageSrc = `${this.cdnBaseUrl}${image}`;
    },
    imageWidth() {
      const windowWidth = this.windowWidth();

      if (windowWidth < 479) {
        return 479;
      } else if (windowWidth < 717) {
        return 717;
      } else if (windowWidth < 1023) {
        return 1023;
      }

      return 1680;
    },
    windowWidth() {
      if (typeof window !== 'undefined') {
        return window.innerWidth;
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
img[lazy=loading] {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

img[lazy=loaded] {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
</style>

