<template>
  <div :class="$s.banner">
    <div :class="$s.content">
      <strong>{{ pageData.header.title }}</strong>
      <o-button
        :class="[$s.button]"
        href="/register"
        variant="primary"
      >
        {{ pageData.header.button }}
      </o-button>
    </div>
  </div>
</template>

<script>
import { OHeading } from 'orbit-ui/components/Heading';
import { OGrid, OGridItem } from 'orbit-ui/components/Grid';
import { OButton } from 'orbit-ui/components/Button';

export default {

  components: {
    OHeading,
    OGrid,
    OGridItem,
    OButton,
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style module="$s" lang="scss">
.banner {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 56px;
  background-color: white;
  box-shadow: var(--box-shadow-static);

  @media (--for-small-up) {
    display: block;
  }
}

.content {
  text-align: left;
  line-height: 56px;
  padding: 0 20px;
  margin: 0 auto;

  @include content-width;
}

.button {
  float: right;

  @media (--for-medium-up) {
    a {
      width: 200px;
      height: 40px;
      line-height: 24px;
    }
  }
}
</style>
