<template>
  <div :class="[$s.section]">
    <o-grid :class="[$s.grid]">
      <o-grid-item :span="12">
        <o-heading
          :class="[$s.whiteHeading, $s.bigHeading]"
          size="display-3"
        >
          {{ pageData.moreFeatures.heading }}
        </o-heading>
        <o-heading
          :class="[$s.whiteHeading, $s.smallHeading]"
          size="title-1"
        >
          {{ pageData.moreFeatures.heading }}
        </o-heading>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :big-desktop="4"
      >
        <div :class="$s.svgContentContainer">
          <cursor-svg :class="[$s.svg]" />
          <o-heading :class="$s.whiteHeading">{{ pageData.moreFeatures.design.title }}</o-heading>
          <p>{{ pageData.moreFeatures.design.description }}</p>
        </div>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :big-desktop="4"
      >
        <div :class="$s.svgContentContainer">
          <cart-svg :class="[$s.svg]" />
          <o-heading :class="$s.whiteHeading">{{ pageData.moreFeatures.ecommerce.title }}</o-heading>
          <p>{{ pageData.moreFeatures.ecommerce.description }}</p>
        </div>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :big-desktop="4"
      >
        <div :class="$s.svgContentContainer">
          <phone-svg :class="[$s.svg]" />
          <o-heading :class="$s.whiteHeading">{{ pageData.moreFeatures.mobile.title }}</o-heading>
          <p>{{ pageData.moreFeatures.mobile.description }}</p>
        </div>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :big-desktop="4"
      >
        <div :class="$s.svgContentContainer">
          <users-svg :class="[$s.svg]" />
          <o-heading :class="$s.whiteHeading">{{ pageData.moreFeatures.admin.title }}</o-heading>
          <p>{{ pageData.moreFeatures.admin.description }}</p>
        </div>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :big-desktop="4"
      >
        <div :class="$s.svgContentContainer">
          <vault-svg :class="[$s.svg]" />
          <o-heading :class="$s.whiteHeading">{{ pageData.moreFeatures.portal.title }}</o-heading>
          <p>{{ pageData.moreFeatures.portal.description }}</p>
        </div>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :big-desktop="4"
      >
        <div :class="$s.svgContentContainer">
          <badge-svg :class="[$s.svg]" />
          <o-heading :class="$s.whiteHeading">{{ pageData.moreFeatures.permissions.title }}</o-heading>
          <p>{{ pageData.moreFeatures.permissions.description }}</p>
        </div>
      </o-grid-item>
    </o-grid>
  </div>
</template>

<script>
import { OHeading } from 'orbit-ui/components/Heading';
import { OGrid, OGridItem } from 'orbit-ui/components/Grid';
import { OButton } from 'orbit-ui/components/Button';

import CursorSvg from '~/assets/img/phoenix/cursor.svg';
import CartSvg from '~/assets/img/phoenix/cart.svg';
import PhoneSvg from '~/assets/img/phoenix/phone.svg';
import UsersSvg from '~/assets/img/phoenix/users.svg';
import VaultSvg from '~/assets/img/phoenix/vault.svg';
import BadgeSvg from '~/assets/img/phoenix/badge.svg';

export default {

  components: {
    OHeading,
    OGrid,
    OGridItem,
    OButton,
    CursorSvg,
    CartSvg,
    PhoneSvg,
    UsersSvg,
    VaultSvg,
    BadgeSvg,
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style module="$s" lang="scss">
.section {
  background-color: #101010;
  color: white;
  width: 100vw;
  max-width: $page-max-width;
}

.grid {
  text-align: center;
  padding: 58px 0 120px;
  margin: 0 auto;

  @include content-width;

  p {
    line-height: 25px;
  }
}

.svgContentContainer {
  margin: 0 auto;
  max-width: 318px;
}

.whiteHeading {
  color: white;
}

.smallHeading {
  display: block;

  @media (--for-tablet-landscape-up) {
    display: none;
  }
}

.bigHeading {
  display: none;
  width: 477px;
  margin: 0 auto;

  @media (--for-tablet-landscape-up) {
    display: block;
  }
}

.svg {
  margin: 40px auto 20px;
  height: 80px;
}
</style>
