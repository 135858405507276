<template>
  <div :class="$s.table">
    <o-heading>Compare our plans</o-heading>
    <p>
      Choose from one of our three site plans based on the features
      you and your clients need for each site you publish.
      Discounted pricing for all your sites is also available by
      upgrading your account to WebPro for a low monthly or annual platform fee.
    </p>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="6">
          <o-heading
            :class="$s.noBottomMargins"
            size="title-5"
          >
            Lite
          </o-heading>
        </o-grid-item>
        <o-grid-item :span="6">
          <o-heading
            :class="$s.noBottomMargins"
            size="title-5"
          >
            WebPro
          </o-heading>
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="6">
          $0 Platform Fee
        </o-grid-item>
        <o-grid-item :span="6">
          $30 Platform Fee
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          <o-heading
            :class="[$s.green, $s.noBottomMargins]"
            size="title-3"
          >
            Pro
          </o-heading>
        </o-grid-item>
        <o-grid-item :span="6">
          <span :class="$s.slightlyLargerText">$15/mo</span>
        </o-grid-item>
        <o-grid-item :span="6">
          <span :class="$s.slightlyLargerText">$8/mo</span>
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          <o-heading
            :class="[$s.blue, $s.noBottomMargins]"
            size="title-3"
          >
            Business
          </o-heading>
        </o-grid-item>
        <o-grid-item :span="6">
          <span :class="$s.slightlyLargerText">$30/mo</span>
        </o-grid-item>
        <o-grid-item :span="6">
          <span :class="$s.slightlyLargerText">$20/mo</span>
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          <o-heading
            :class="[$s.purple, $s.noBottomMargins]"
            size="title-3"
          >
            Performance
          </o-heading>
        </o-grid-item>
        <o-grid-item :span="6">
          <span :class="$s.slightlyLargerText">$45/mo</span>
        </o-grid-item>
        <o-grid-item :span="6">
          <span :class="$s.slightlyLargerText">$30/mo</span>
        </o-grid-item>
      </o-grid>
    </div>

    <div :class="$s.spacer"/>

    <o-heading size="title-5">Site Features</o-heading>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          No Weebly Ads
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Unlimited Storage
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Connect Your Domain
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          SSL Security
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Advanced Site Stats
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Site Search
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Password Protection
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Video Backgrounds
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.green"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Members
        </o-grid-item>
        <o-grid-item :span="4">
          Up to 100
        </o-grid-item>
        <o-grid-item :span="4">
          Unlimited
        </o-grid-item>
        <o-grid-item :span="4">
          Unlimited
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Membership Registration
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>

    <div :class="$s.spacer"/>

    <o-heading size="title-5">Ecommerce Features</o-heading>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Weebly Transaction Fee
        </o-grid-item>
        <o-grid-item :span="4">
          3%
        </o-grid-item>
        <o-grid-item :span="4">
          0%
        </o-grid-item>
        <o-grid-item :span="4">
          0%
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Number of Products
        </o-grid-item>
        <o-grid-item :span="4">
          Up to 25
        </o-grid-item>
        <o-grid-item :span="4">
          Unlimited
        </o-grid-item>
        <o-grid-item :span="4">
          Unlimited
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Checkout
        </o-grid-item>
        <o-grid-item :span="4">
          On Weebly
        </o-grid-item>
        <o-grid-item :span="4">
          Your domain
        </o-grid-item>
        <o-grid-item :span="4">
          Your domain
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Digital Goods
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Product Reviews
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Inventory Management
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.blue"
            name="check"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Tax Calculator
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.lightGrid">
      <o-grid>
        <o-grid-item :span="12">
          Coupons
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
    <div :class="$s.darkGrid">
      <o-grid>
        <o-grid-item :span="12">
          Real Time Shipping Rates
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.grey"
            name="circle"
          />
        </o-grid-item>
        <o-grid-item :span="4">
          <o-icon
            :class="$s.purple"
            name="check"
          />
        </o-grid-item>
      </o-grid>
    </div>
  </div>
</template>

<script>
import { OIcon } from 'orbit-ui/components/Icon';
import { OGrid, OGridItem } from 'orbit-ui/components/Grid';
import { OHeading } from 'orbit-ui/components/Heading';

export default {
  components: {
    OIcon,
    OGrid,
    OGridItem,
    OHeading,
  },
};
</script>

<style lang="scss" module="$s">
.table {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.darkGrid, .lightGrid {
  & > * {
    padding: 16px 10px;
    max-width: 300px;
    margin: 0 auto;
  }

  & > * > * {
    grid-gap: 10px;
  }
}
.darkGrid {
  background-color: #F9F9F9;
}

.noBottomMargins {
  margin-bottom: 0 !important;
}

.spacer {
  height: 32px;
}

.green {
  color: #1AB40E;
}

.blue {
  color: #3363FF;
}

.purple {
  color: #AB62FA;
}

.grey {
  color: #CFCFCF;
}

.slightlyLargerText {
  font-size: 18px;
}
</style>
