<template>
  <div :class="$s.featureSection">
    <o-grid
      :class="[$s.grid]"
      vertical-align="middle"
    >
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :class="[$s.boostDescriptionContainer]"
      >
        <o-heading>{{ pageData.features.efficiency.title }}</o-heading>
        <p>{{ pageData.features.efficiency.description }}</p>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :class="[$s.boostImageContainer]"
      >
        <phoenix-responsive-image
          :class="$s.boostImage"
          filename="boost" />
      </o-grid-item>
      <o-grid-item
        :span="12"
        :class="[$s.designsDescriptionContainer]"
      >
        <div :class="$s.designsDescriptionContent">
          <o-heading>{{ pageData.features.ease.title }}</o-heading>
          <p>{{ pageData.features.ease.description }}</p>
        </div>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :class="[$s.designsImageContainer]"
      >
        <phoenix-responsive-image
          :class="$s.designsImage"
          filename="designs" />
      </o-grid-item>
      <o-grid-item
        :span="12"
        :class="[$s.spacer]"/>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :class="[$s.clientsImageContainer]"
      >
        <phoenix-responsive-image
          :class="$s.clients1Image"
          filename="clients1" />
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :class="[$s.clientsDescriptionContainer]"
      >
        <o-heading>{{ pageData.features.clients.title }}</o-heading>
        <p>{{ pageData.features.clients.description }}</p>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :class="[$s.payImageContainer]"
      >
        <phoenix-responsive-image
          :class="$s.clients2Image"
          filename="clients2" />
      </o-grid-item>
      <o-grid-item
        :span="12"
        :tablet-landscape="6"
        :class="[$s.payDescriptionContainer]"
      >
        <o-heading>{{ pageData.features.payment.title }}</o-heading>
        <p>{{ pageData.features.payment.description }}</p>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :class="[$s.laptopImageContainer]"
      >
        <phoenix-responsive-image
          :class="$s.clients3Image"
          filename="clients3" />
      </o-grid-item>
    </o-grid>
  </div>
</template>

<script>
import { OHeading } from 'orbit-ui/components/Heading';
import { OGrid, OGridItem } from 'orbit-ui/components/Grid';
import { OButton } from 'orbit-ui/components/Button';

import PhoenixResponsiveImage from '~/components/phoenix/PhoenixResponsiveImage';

export default {

  components: {
    OHeading,
    OGrid,
    OGridItem,
    OButton,
    PhoenixResponsiveImage,
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style module="$s" lang="scss">
@import "orbit-ui/vars.css";

.featureSection {
  margin: 0 auto 32px;

  @include content-width;
}

.boostImage,
.clients1Image,
.clients2Image {
  width: 100%;
  max-width: 350px;

  @media (--for-tablet-landscape-up) {
    max-width: 500px;
  }
}

.grid {
  text-align: center;
  padding-top: 50px;
  width: 100%;
  margin: 0 auto;

  p {
    line-height: 25px;
  }
}

.boostDescriptionContainer {
  order: 0;

  @media (--for-tablet-landscape-up) {
    order: 1;
    text-align: left;
  }
}

.boostImageContainer {
  order: 1;

  @media (--for-tablet-landscape-up) {
    order: 0;
  }
}

.designsDescriptionContainer {
  order: 2;

  .designsDescriptionContent {
    width: 100%;

    @media (--for-tablet-landscape-up) {
      width: 600px;
      margin: 0 auto;
    }
  }
}

.designsImageContainer {
  order: 3;
}

.designsImage {
  width: calc(100vw + 20px);
  position: absolute;
  left: 0;
  z-index: -1; // prevents this from appearing over the sticky top bar
}

.spacer {
  order: 4;

  height: calc(((482 / 1434) * 100vw) + 40px);

  @media only screen and (min-width: 717px) {
    height: calc(((482 / 2046) * 100vw) + 40px);
  }
}

.clientsImageContainer {
  order: 5;

  @media (--for-tablet-landscape-up) {
    order: 6;
  }
}

.clientsDescriptionContainer {
  order: 6;

  @media (--for-tablet-landscape-up) {
    order: 5;
    text-align: left;
  }
}

.payImageContainer {
  order: 7;
}

.payDescriptionContainer {
  order: 8;

  @media (--for-tablet-landscape-up) {
    text-align: left;
  }
}

.laptopImageContainer {
  order: 9;
  padding: 64px 0;

  @media (--for-tablet-landscape-up) {
    padding: 100px 0;
  }

  img {
    width: 100%;
  }
}
</style>
