<template>
  <div :class="$s.container">
    <div :class="$s.navSection">
      <o-nav
        :class="$s.NavContainer"
        horizontal
      >
        <o-nav-link
          :class="$s.WeeblyLogo"
          to="//www.weebly.com"
        >
          <WeeblySquareLogo
            :class="$s.DesktopLogo"
          />
          <WeeblyLogotype
            :class="$s.MobileLogo"
          />
        </o-nav-link>
        <div :class="$s.AccountContainer">
          <o-button
            :class="[$s.SignInButton, $s.Active]"
            href="/login"
            variant="primary"
            size="large"
          >
            {{ pageData.header.signInButton }}
          </o-button>
        </div>
      </o-nav>
    </div>
    <div :class="$s.topSection">
      <div :class="$s.textContainer">
        <o-heading
          :class="[$s.textCenter, $s.headline, $s.title]"
          size="title-5"
        >
          {{ pageData.header.title }}
        </o-heading>
        <o-heading
          :class="[$s.textCenter, $s.headline, $s.caption, $s.bigCaption]"
          size="display-1"
        >
          <span v-html="pageData.header.caption"/>
        </o-heading>
        <o-heading
          :class="[$s.textCenter, $s.headline, $s.caption, $s.mediumCaption]"
          size="display-2"
        >
          <span v-html="pageData.header.caption"/>
        </o-heading>
        <o-heading
          :class="[$s.textCenter, $s.headline, $s.caption, $s.smallCaption]"
          size="display-3"
        >
          <span v-html="pageData.header.caption"/>
        </o-heading>
        <o-button
          :class="[$s.blockCenter, $s.startButton]"
          variant="primary"
          href="/register"
        >
          {{ pageData.header.button }}
        </o-button>
      </div>
    </div>
    <div :class="$s.headerImageContainer">
      <div
        ref="headerImage"
        :style="{
          'background-image': imageSrc
        }"
        :class="$s.headerImage"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';
import { OHeading } from 'orbit-ui/components/Heading';
import { OGrid, OGridItem } from 'orbit-ui/components/Grid';
import { OButton } from 'orbit-ui/components/Button';
import { ONav, ONavLink } from 'orbit-ui/components/Nav';

import PhoenixResponsiveImage from '~/components/phoenix/PhoenixResponsiveImage';

import WeeblyLogotype from '~/assets/img/weebly-logotype.svg';
import WeeblySquareLogo from '~/assets/img/weebly-square-logo.svg';
import MobileHeaderImage from '~/assets/img/phoenix/header-479.png';
import TabletHeaderImage from '~/assets/img/phoenix/header-717.png';
import DesktopHeaderImage from '~/assets/img/phoenix/header-1023.png';
import DesktopXlHeaderImage from '~/assets/img/phoenix/header-1680.png';

let debouncedScrollHandler = null;
let debouncedImageResizeHandler = null;

export default {

  components: {
    OHeading,
    ONav,
    ONavLink,
    OGrid,
    OGridItem,
    OButton,
    PhoenixResponsiveImage,
    WeeblyLogotype,
    WeeblySquareLogo,
    MobileHeaderImage,
    TabletHeaderImage,
    DesktopHeaderImage,
    DesktopXlHeaderImage,
  },

  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      topBarActive: false,
      imageSrc: '',
    };
  },

  computed: {
    ...mapGetters([
      'cdnBaseUrl',
    ]),
  },

  mounted() {
    debouncedScrollHandler = debounce(this.checkImagePosition.bind(this), 50, { leading: true });
    window.addEventListener('scroll', debouncedScrollHandler);

    this.responsiveImageResize();
    debouncedImageResizeHandler = debounce(this.responsiveImageResize.bind(this), 200, { leading: true });
    window.addEventListener('resize', debouncedImageResizeHandler);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', debouncedScrollHandler);
    window.removeEventListener('resize', debouncedImageResizeHandler);
  },

  methods: {
    responsiveImageResize() {
      let image = null;
      const windowWidth = this.windowWidth();

      if (windowWidth < 479) {
        image = MobileHeaderImage;
      } else if (windowWidth < 717) {
        image = TabletHeaderImage;
      } else if (windowWidth < 1023) {
        image = DesktopHeaderImage;
      } else {
        image = DesktopXlHeaderImage;
      }

      this.imageSrc = `url('${this.cdnBaseUrl}${image}')`;
    },
    windowWidth() {
      if (typeof window !== 'undefined') {
        return window.innerWidth;
      }

      return null;
    },
    checkImagePosition() {
      const rect = this.$refs.headerImage.getBoundingClientRect();
      if (rect.top * -1 > (rect.height / 2) && !this.topBarActive) {
        this.topBarActive = true;
        this.$emit('showTopBar');
      } else if (rect.top * -1 < (rect.height / 2) && this.topBarActive) {
        this.topBarActive = false;
        this.$emit('hideTopBar');
      }
    },
  },
};
</script>

<style module="$s" lang="scss">
.container, .navSection, .topSection {
  width: 100vw;
  max-width: $page-max-width;
}

.navSection {
  height: 0;
}

.topSection {
  background: linear-gradient(to bottom, #0D0D0D, #131313 137px);
  margin: 0;
  padding: 100px 0 32px;
  text-align: center;
}

.textContainer {
  padding: 0 20px;
}

.headline {
  width: 100%;
  color: white !important;
}

.title {
  font-size: 12px;
}

.navContainer, .textContainer {
  margin: 0 auto;

  @include content-width;
}

.caption {
  margin: 0 auto;
  padding: 0 0 30px;
  width: 100%;

  &.smallCaption {
    display: block;

    @media (--for-medium-up) {
      display: none;
    }
  }

  &.mediumCaption {
    display: none;

    @media (--for-medium-up) {
      display: block;
    }

    @media (--for-large-up) {
      display: none;
    }
  }

  &.bigCaption {
    display: none;

    @media (--for-large-up) {
      display: block;
    }
  }
}

.startButton {
  a {
    width: 180px;
  }
}

.headerImageContainer {
  background: linear-gradient(to bottom, #131313 50%, white 50%);

  .headerImage {
    margin: 0 auto;
    background-size: cover;
    display: block;
    height: calc(276px * 1400 / 2072);

    @include content-width;

    @media (--for-extra-small-up) {
      height: calc(324px * 1400 / 2072);
    }

    @media (--for-small-up) {
      height: calc(424px * 1400 / 2072);
    }

    @media (--for-medium-up) {
      height: calc(680px * 1400 / 2072);
    }

    @media (--for-tablet-landscape-up) {
      height: calc(868px * 1400 / 2072);
    }

    @media (--for-extra-large-up) {
      height: calc(1016px * 1400 / 2072);
    }
  }
}

.navSection {
  background-color: #101010;
}

.NavContainer {
  height: 50px;
  margin: 0 auto;
  position: relative;
  top: 50px;

  @include content-width;

  ul {
    align-items: center;
    justify-content: flex-start;
  }

  .AccountContainer {
    margin-left: var(--space-x6);
    position: absolute;
    right: 0px;

    .SignUpButton,
    .SignInButton,
    .DomainButton {
      a,
      button {
        // This nesting is here to override orbit styles
        font-size: 16px;
      }
    }
  }

  svg path {
    fill: white;
  }
}

.WeeblyLogo {
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  align-self: center;
  margin: 0 auto;
  width: 103px;

  @media (--for-extra-large-up) {
    width: 127px;
  }

  .MobileLogo {
    display: block;

    @media (--for-extra-large-up) {
      display: none;
    }
  }

  .DesktopLogo {
    display: none;

    @media (--for-extra-large-up) {
      display: block;
    }
  }
}

.SignInButton {
  display: none;
  margin-left: var(--space-x2);
  opacity: 0;
  transition: opacity var(--transition-base);

  @media (--for-medium-up) {
    display: inline-block;
  }

  &.Active {
    opacity: 1;
  }

  a {
    min-width: 123px;
    height: 40px;
    line-height: 37px;
    border: none;
    border-radius: 4px;
    padding: 0 var(--space-x4);
    background-color: transparent;
    color: white;

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }
}
</style>
