<template>
  <div
    id="pricing"
    :class="[$s.section]">
    <o-grid :class="[$s.grid]">
      <o-grid-item :span="12">
        <o-heading size="display-2">{{ pageData.pricing.heading }}</o-heading>
        <p :class="$s.pricingDescription">{{ pageData.pricing.description }}</p>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :desktop="6"
      >
        <div :class="$s.pricingBox">
          <div :class="$s.pricingContent">
            <o-heading :class="[$s.whiteHeading, $s.planName]">{{ pageData.pricing.lite.planName }}</o-heading>
            <o-heading
              :class="[$s.whiteHeading, $s.planCost]"
              size="display-2"
            >
              {{ pageData.pricing.lite.planCost }}<br>
            </o-heading>
            <p><strong>{{ pageData.pricing.platformFee }}</strong></p>
            <p>{{ pageData.pricing.lite.siteFeatures }}</p>
            <p><o-icon name="plus"/></p>
            <p>{{ pageData.pricing.websitesStartingAt }}</p>
            <o-heading
              :class="[$s.whiteHeading, $s.planCost]"
              size="display-2"
            >
              {{ pageData.pricing.lite.siteCost }}<br>
            </o-heading>
            <p><strong>{{ pageData.pricing.perMonth }}</strong></p>
          </div>
        </div>
      </o-grid-item>
      <o-grid-item
        :span="12"
        :desktop="6"
      >
        <div :class="$s.pricingBox">
          <div :class="$s.pricingContent">
            <o-heading :class="[$s.whiteHeading, $s.planName]">{{ pageData.pricing.webpro.planName }}</o-heading>
            <o-heading
              :class="[$s.whiteHeading, $s.planCost]"
              size="display-2"
            >
              {{ pageData.pricing.webpro.planCost }}<br>
            </o-heading>
            <p><strong>{{ pageData.pricing.platformFee }}</strong></p>
            <p>{{ pageData.pricing.webpro.siteFeatures }}</p>
            <p><o-icon name="plus"/></p>
            <p>{{ pageData.pricing.websitesStartingAt }}</p>
            <o-heading
              :class="[$s.whiteHeading, $s.planCost]"
              size="display-2"
            >
              {{ pageData.pricing.webpro.siteCost }}<br>
            </o-heading>
            <p><strong>{{ pageData.pricing.perMonth }}</strong></p>
          </div>
        </div>
      </o-grid-item>
      <o-grid-item :span="12">
        <div :class="$s.startNowBox">
          <o-button
            :class="$s.startNowButton"
            href="/register"
          >
            {{ pageData.header.button }}
          </o-button>
        </div>
      </o-grid-item>
      <o-grid-item :span="12">
        <div
          :class="$s.detailsBox"
          @click="openPricingTakeover"
        >
          <p><strong>{{ pageData.pricing.viewPlanDetails }} <o-icon
            :class="$s.detailsIcon"
            name="arrow-right"
          /></strong></p>
          <details-plus />
        </div>
      </o-grid-item>
    </o-grid>
  </div>
</template>

<script>
import { OHeading } from 'orbit-ui/components/Heading';
import { OGrid, OGridItem } from 'orbit-ui/components/Grid';
import { OButton } from 'orbit-ui/components/Button';
import { OIcon } from 'orbit-ui/components/Icon';
import { LayerAPI } from 'orbit-ui/components/Layer';

import DetailsPlus from '~/assets/img/phoenix/details-plus.svg';
import PhoenixPricingTakeover from '~/components/phoenix/PhoenixPricingTakeover';

export default {

  components: {
    OHeading,
    OGrid,
    OGridItem,
    OButton,
    OIcon,
    DetailsPlus,
  },

  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },

  inject: {
    layer: LayerAPI,
  },

  methods: {
    openPricingTakeover() {
      this.layer.open(PhoenixPricingTakeover);
    },
  },
};
</script>

<style module="$s" lang="scss">
.section {
  background-color: #EDEFF0;
  width: 100vw;
  max-width: $page-max-width;
}

.grid {
  margin: 0 auto;
  text-align: center;
  padding: 50px 0 56px;
  @include content-width;

  h2 {
    line-height: 30px;
  }

  p {
    line-height: 25px;
  }
}

.pricingDescription {
  margin: 0 auto 32px;
  max-width: 627px;
}

.pricingBox {
  background-color: #101010;
  color: white;
  padding: 48px 28px;

  .pricingContent {
    max-width: 242px;
    margin: 0 auto;
  }

  .planName {
    padding-bottom: 40px;
  }
}


.whiteHeading {
  color: white;
}


.planCost {
  margin-bottom: 0 !important;

  & + p {
    margin-top: 0;
  }
}

.startNowBox {
  background-color: white;
  padding: 48px;
}

.startNowButton {
  width: 100%;
  max-width: 200px;

  a {
    width: 100%;
    background-color: #101010;
    color: white;
    position: static;
    border: none;

    &:hover {
      background-color: #2A2A2A;
      border-color: #2A2A2A;
    }

    &:active {
      background-color: #2A2A2A;
    }
  }
}

.detailsBox {
  * {
    cursor: pointer;
  }

  p {
    margin-bottom: 0 !important;
    padding-bottom: 4px;
  }
}
</style>
