<template>
  <div :class="$s.takeover">
    <o-icon
      :class="$s.close"
      name="x"
      @click="closeTakeover" />
    <div :class="$s.contentBox">
      <tiny-pricing-table :class="$s.tinyTable" />
      <large-pricing-table :class="$s.bigboiTable" />
    </div>
  </div>
</template>

<script>
import { OIcon } from 'orbit-ui/components/Icon';
import { OLayer, LayerAPI } from 'orbit-ui/components/Layer';

import TinyPricingTable from '~/components/phoenix/TinyPricingTable';
import LargePricingTable from '~/components/phoenix/LargePricingTable';

export default {
  components: {
    OIcon,
    TinyPricingTable,
    LargePricingTable,
  },

  inject: {
    layer: OLayer.key,
    layerAPI: LayerAPI,
  },

  methods: {
    closeTakeover() {
      this.layer.close();
    },
  },
};
</script>


<style lang="scss" module="$s">
.takeover {
  height: 100vh;
  width: 100vw;
  background-color: white;
  overflow-y: scroll;

  .close {
    position: absolute;
    top: 32px;
    left: 32px;
    cursor: hand;
  }

  .contentBox {
    width: calc(100% - 160px);
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 0 auto;
  }

  p {
    margin-top: 0;
    text-align: center;
    line-height: 25px;
  }

  header:before {
    display: none !important;
  }

  main {
    padding-top: 0 !important;
  }

  .tinyTable {
    display: block;

    @media (--for-tablet-landscape-up) {
      display: none;
    }
  }

  .bigboiTable {
    display: none;

    @media (--for-tablet-landscape-up) {
      display: block;
      max-width: 1000px;
    }
  }
}
</style>
